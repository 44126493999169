import { Monitor } from '@sortlist-frontend/mlm';
import { isNonEmptyString } from '@sortlist-frontend/utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '_core/api/api-public-api';
import { Domain } from '_types/public-api';

import { getHost } from '../../utils/public-links/utils';
import { DEFAULT_LOCAL_ORIGIN_DOMAIN } from '../public-api.repo';

const domainQuery = `
  query getDomains($host: String!) {
    domain(host: $host) {
        id
        host
        availableLocales {
            id
            code
        }
        location {
            id
            name
        }
    }
  }
`;

export const getDomainOrigin = (origin: string) => {
  if (['localhost', 'pr-'].some((host) => origin.includes(host))) {
    return DEFAULT_LOCAL_ORIGIN_DOMAIN;
  }
  return origin;
};

export const domainCacheKey = (host: string) => ['domain', host];

export const domainRepo = {
  getDomain: async (origin: string): Promise<Domain> => {
    const domainOrigin = getDomainOrigin(origin);
    const host = getHost(domainOrigin);

    const returnObject = await api.post('/graphql', { query: domainQuery, variables: { host } });

    const domain = returnObject?.data?.data?.domain;

    if (domain == null) {
      Monitor.captureException(
        new Error(`Domain not found for host: ${host}, response: ${JSON.stringify(returnObject?.data?.errors ?? {})}`),
      );
    }

    return domain;
  },
};

const oneDay = 1000 * 60 * 60 * 24;

export const useDomain = (origin: string) => {
  return useQuery({
    queryKey: domainCacheKey(origin),
    queryFn: () => domainRepo.getDomain(origin),
    staleTime: oneDay,
    enabled: isNonEmptyString(origin),
  });
};
