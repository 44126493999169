import { Monitor } from '@sortlist-frontend/mlm';
import { capitalize, isNonEmptyString } from '@sortlist-frontend/utils';
import { useQuery } from '@tanstack/react-query';

import { api, PUBLIC_API_PROXY_URL } from '_core/api/api-nextjs-proxy';
import { acceptLanguageHeader } from '_core/api/api.utils';
import { Domain, GraphqlPageInfo, SearchTopic, TopicExternalType } from '_types/public-api';

import { DEFAULT_LOCAL_ORIGIN_DOMAIN } from '../public-api.repo';

const topicsQuery = `
  query searchTopics(
    $searchText: String!,
    $topicTypes: [TopicExternalType!]!,
    $host: String!,
    $associatedTopics: [ID!],
    $locale: ID!
  ) {
    searchTopics(
      first: 10,
      searchText: $searchText,
      types: $topicTypes,
      pageFilter: {
        host: $host,
        locale: $locale,
        topics: $associatedTopics
      }
    ) {
      edges {
        node {
          ...on NamedTopic {
            id
            name
          }
        }
        page {
          id
          url
        }
      }
    }
  }
`;

const topicsQueryForFilters = `
  query searchTopics($searchText: String!, $topicTypes: [TopicExternalType!]!) {
    searchTopics(
      first: 10,
      searchText: $searchText,
      types: $topicTypes,
      ) {
      edges {
        node {
          ...on NamedTopic {
            id
            name
          }
          ... on Location {
            simpleAddress
          }
        }
      }
    }
  }
`;

export type TopicType = 'service' | 'location';

const SERVICE_RELATED_TOPIC_TYPES: TopicExternalType[] = [
  'CATEGORY',
  'EXPERTISE',
  'FEATURE',
  'FRAMEWORK',
  'PLATFORM',
  'SKILL',
];

const LOCATION_RELATED_TOPIC_TYPES: TopicExternalType[] = ['LOCATION', 'CUSTOM'];

type TopicNode = {
  node: {
    id: string;
    name: string;
    simpleAddress?: string;
  };
};

type SearchTopicResponse = TopicNode & {
  page: {
    id: string;
    url: string;
  };
};

export type LanguageTopic = {
  id: string;
  name: string;
};

export type TopicsParams = {
  topicTypes: TopicExternalType[];
  host: string;
  associatedTopics: string[];
  locale: string;
};

export type TopicsForFiltersParams = {
  topicTypes: TopicExternalType[];
};

const getResultUrl = (url: string, baseURL: string) => {
  let replaceUrl = null;

  // easier to work in development/sandbox
  if (['localhost', 'pr-'].some((host) => baseURL.includes(host))) {
    replaceUrl = baseURL;
  }
  if (replaceUrl != null) {
    return url.replace('https://', '').replace(DEFAULT_LOCAL_ORIGIN_DOMAIN, replaceUrl);
  }
  return url;
};

export const topicsRepo = {
  getTopics: async (
    params: TopicsParams,
    locale: string,
    searchText: string,
    baseURL: string,
  ): Promise<(SearchTopic & { url: string })[]> => {
    if (params == null) {
      return [];
    }

    const returnObject = await api.post(
      PUBLIC_API_PROXY_URL,
      { query: topicsQuery, variables: { ...params, searchText } },
      { baseURL, headers: { 'Accept-Language': acceptLanguageHeader(locale) } },
    );

    const response = returnObject?.data;
    const results: SearchTopicResponse[] = response?.data?.searchTopics?.edges ?? [];

    if (response?.errors != null) {
      Monitor.captureException(
        new Error(
          `Error for searchTopics: variables: ${JSON.stringify(params)}, searchText: ${searchText}, errors: ${JSON.stringify(
            returnObject?.data?.errors ?? {},
          )}`,
        ),
      );
    }

    return [...results]
      .map((result) => ({
        id: result.node.id,
        name: capitalize(result.node.name),
        url: getResultUrl(result.page.url, baseURL),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  getTopicsForFilters: async (
    params: { topicTypes: TopicExternalType[] },
    locale: string,
    searchText: string,
    baseURL: string,
  ): Promise<SearchTopic[]> => {
    if (params == null) {
      return [];
    }

    const returnObject = await api.post(
      PUBLIC_API_PROXY_URL,
      { query: topicsQueryForFilters, variables: { ...params, searchText } },
      { baseURL, headers: { 'Accept-Language': acceptLanguageHeader(locale) } },
    );

    const response = returnObject?.data;
    const results: SearchTopicResponse[] = response?.data?.searchTopics?.edges ?? [];

    if (response?.errors != null) {
      Monitor.captureException(
        new Error(`Error for searchTopics: ${JSON.stringify(returnObject?.data?.errors ?? {})}`),
      );
    }

    return results
      .map((result) => ({
        id: result.node.id,
        name: capitalize(result.node.simpleAddress ?? result.node.name),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  },

  getLanguagesTopics: async (locale: string, baseURL: string): Promise<LanguageTopic[]> => {
    const response = await api.post(`${PUBLIC_API_PROXY_URL}/languages`, { locale }, { baseURL });
    const languagesNodes = response.data;

    const results: LanguageTopic[] = languagesNodes
      .filter((edge: TopicNode | null) => edge?.node != null)
      .map((edge: TopicNode) => ({
        id: edge.node.id,
        name: edge.node.name,
      }))
      .sort((a: LanguageTopic, b: LanguageTopic) => a.name.localeCompare(b.name));

    return results;
  },
};

export const useTopics = (
  domain: Domain | undefined,
  topics: string[],
  locale: string,
  type: TopicType,
  searchText: string,
  baseURL: string,
  enabled = true,
) => {
  return useQuery({
    queryKey: ['topics', domain?.host ?? 'nodomain', topics.join(','), locale, type, searchText],
    queryFn: () => {
      if (domain == null) {
        return Promise.resolve([]);
      }
      const localeId = domain.availableLocales.find((domainLocale) => domainLocale.code === locale)?.id;
      if (localeId == null) {
        return Promise.resolve([]);
      }

      const params: TopicsParams = {
        topicTypes: type === 'service' ? SERVICE_RELATED_TOPIC_TYPES : LOCATION_RELATED_TOPIC_TYPES,
        host: domain.host,
        associatedTopics: [localeId, ...topics],
        locale: localeId,
      };

      return topicsRepo.getTopics(params, locale, searchText, baseURL);
    },
    enabled: enabled && Boolean(domain != null) && isNonEmptyString(searchText),
  });
};

export const useTopicsForFilters = (
  domain: Domain | undefined,
  topics: string[],
  locale: string,
  type: TopicType,
  searchText: string,
  baseURL: string,
  enabled = true,
) => {
  return useQuery({
    queryKey: ['topics', domain?.host ?? 'nodomain', topics.join(','), locale, type, searchText],
    queryFn: () => {
      if (domain == null) {
        return Promise.resolve([]);
      }

      const params: TopicsForFiltersParams = {
        topicTypes: type === 'service' ? SERVICE_RELATED_TOPIC_TYPES : LOCATION_RELATED_TOPIC_TYPES,
      };

      return topicsRepo.getTopicsForFilters(params, locale, searchText, baseURL);
    },
    enabled: enabled && Boolean(domain != null) && isNonEmptyString(searchText),
  });
};

export const useLanguagesTopics = (locale: string, baseURL: string, enabled = true) => {
  return useQuery({
    queryKey: ['languages', locale],
    queryFn: () => {
      return topicsRepo.getLanguagesTopics(locale, baseURL);
    },
    enabled: enabled,
  });
};
